import React from "react";
import Image from "gatsby-image";
import LinkIcon from "../../Link/LinkIcon";
import { Link } from "gatsby";

const ArticlesList = ({ articles }) => {
  return (
    <div className="grid lg:gap-10 lg:grid-cols-3 gap-y-10 px-ogs">
      {articles.map((article, index) => (
        <div
          key={index}
          className="space-y-1 w-full mb-auto flex flex-col h-full"
        >
          <Link to={article.url}>
            {article.data.preview_image.fluid && (
              <Image
                placeholderStyle={{ display: "none" }}
                fluid={{
                  ...article.data.preview_image.fluid,
                  aspectRatio: 2
                }}
                loading="lazy"
              />
            )}
          </Link>
          <p className="text-xs mt-0">{article.data.published_date}</p>
          <h2 className="text-lg font-medium pt-3">
            {article.data.title.text}
          </h2>
          <p className="leading-7 font-light rte flex-grow">
            {article.data.subtitle.text}
          </p>
          <Link
            to={article.url}
            className="inline-flex gap-2 pt-4 items-center link w-content"
            style={{ width: "fit-content" }}
          >
            <div className="h-5 w-5">
              <LinkIcon />
            </div>
            <p className="tracking-wider uppercase pb-1 text-sm font-semibold">
              Read more
            </p>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default ArticlesList;
